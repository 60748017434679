import Project1image1 from './img/Zrealizovane/Project1/image1.jpg';
import Project1image2 from './img/Zrealizovane/Project1/image2.jpg';
import Project1image3 from './img/Zrealizovane/Project1/image3.jpg';
import Project1image4 from './img/Zrealizovane/Project1/image4.jpg';
import Project1image5 from './img/Zrealizovane/Project1/image5.jpg';
import Project1image8 from './img/Zrealizovane/Project1/image8.jpg';
import Project1image9 from './img/Zrealizovane/Project1/image9.jpg';
import Project1image10 from './img/Zrealizovane/Project1/image10.jpg';
import Project1image11 from './img/Zrealizovane/Project1/image11.jpg';

import Project2image1 from './img/Zrealizovane/Project2/image1.jpg';
import Project2image2 from './img/Zrealizovane/Project2/image2.jpg';
import Project2image3 from './img/Zrealizovane/Project2/image3.jpg';
import Project2image4 from './img/Zrealizovane/Project2/image4.jpg';
import Project2image5 from './img/Zrealizovane/Project2/image5.jpg';
import Project2image6 from './img/Zrealizovane/Project2/image6.jpg';
import Project2image7 from './img/Zrealizovane/Project2/image7.jpg';
import Project2image8 from './img/Zrealizovane/Project2/image8.jpg';
import Project2image9 from './img/Zrealizovane/Project2/image9.jpg';
import Project2image10 from './img/Zrealizovane/Project2/image10.jpg';
import Project2image11 from './img/Zrealizovane/Project2/image11.jpg';
import Project2image12 from './img/Zrealizovane/Project2/image12.jpg';
import Project2image13 from './img/Zrealizovane/Project2/image13.jpg';
import Project2image14 from './img/Zrealizovane/Project2/image14.jpg';

import Project3image1 from './img/Zrealizovane/Project3/image1.jpg';
import Project3image2 from './img/Zrealizovane/Project3/image2.jpg';
import Project3image3 from './img/Zrealizovane/Project3/image3.jpg';
import Project3image4 from './img/Zrealizovane/Project3/image4.jpg';
import Project3image5 from './img/Zrealizovane/Project3/image5.jpg';
import Project3image6 from './img/Zrealizovane/Project3/image6.jpg';

import Project4image1 from './img/Zrealizovane/Project4/image1.jpg';
import Project4image2 from './img/Zrealizovane/Project4/image2.jpg';
import Project4image3 from './img/Zrealizovane/Project4/image3.jpg';
import Project4image8 from './img/Zrealizovane/Project4/image8.jpg';
import Project4image11 from './img/Zrealizovane/Project4/image11.jpg';
import Project4image12 from './img/Zrealizovane/Project4/image12.jpg';
import Project4image13 from './img/Zrealizovane/Project4/image13.jpg';
import Project4image14 from './img/Zrealizovane/Project4/image14.jpg';
import Project4image15 from './img/Zrealizovane/Project4/image15.jpg';
import Project4image16 from './img/Zrealizovane/Project4/image16.jpg';
import Project4image19 from './img/Zrealizovane/Project4/image19.jpg';

import Project5image1 from './img/Zrealizovane/Project5/image1.jpg';
import Project5image2 from './img/Zrealizovane/Project5/image2.jpg';
import Project5image3 from './img/Zrealizovane/Project5/image3.jpg';
import Project5image4 from './img/Zrealizovane/Project5/image4.jpg';
import Project5image5 from './img/Zrealizovane/Project5/image5.jpg';
import Project5image6 from './img/Zrealizovane/Project5/image6.jpg';
import Project5image8 from './img/Zrealizovane/Project5/image8.jpg';
import Project5image9 from './img/Zrealizovane/Project5/image9.jpg';

import Project6image1 from './img/Zrealizovane/Project6/image1.jpg';
import Project6image2 from './img/Zrealizovane/Project6/image2.jpg';
import Project6image3 from './img/Zrealizovane/Project6/image3.jpg';
import Project6image4 from './img/Zrealizovane/Project6/image4.jpg';
import Project6image5 from './img/Zrealizovane/Project6/image5.jpg';
import Project6image6 from './img/Zrealizovane/Project6/image6.jpg';
import Project6image10 from './img/Zrealizovane/Project6/image10.jpg';

import Project7image1 from './img/Zrealizovane/Project7/image1.jpg';
import Project7image2 from './img/Zrealizovane/Project7/image2.jpg';
import Project7image3 from './img/Zrealizovane/Project7/image3.jpg';
import Project7image4 from './img/Zrealizovane/Project7/image4.jpg';

import Project8image1 from './img/Zrealizovane/Project8/image1.jpg';
import Project8image2 from './img/Zrealizovane/Project8/image2.jpg';
import Project8image3 from './img/Zrealizovane/Project8/image3.jpg';

import Project9image1 from './img/Zrealizovane/Project9/image1.jpeg';
import Project9image2 from './img/Zrealizovane/Project9/image2.jpeg';
import Project9image3 from './img/Zrealizovane/Project9/image3.jpeg';

import Project10image1 from './img/Zrealizovane/Project10/image1.jpg';
import Project10image2 from './img/Zrealizovane/Project10/image2.jpg';
import Project10image3 from './img/Zrealizovane/Project10/image3.jpg';
import Project10image4 from './img/Zrealizovane/Project10/image4.jpg';
import Project10image5 from './img/Zrealizovane/Project10/image5.jpg';
import Project10image6 from './img/Zrealizovane/Project10/image6.jpg';
import Project10image7 from './img/Zrealizovane/Project10/image7.jpg';
import Project10image8 from './img/Zrealizovane/Project10/image8.jpg';
import Project10image9 from './img/Zrealizovane/Project10/image9.jpg';
import Project10image10 from './img/Zrealizovane/Project10/image10.jpg';
import Project10image11 from './img/Zrealizovane/Project10/image11.jpg';
import Project10image12 from './img/Zrealizovane/Project10/image12.jpg';
import Project10image13 from './img/Zrealizovane/Project10/image13.jpg';
import Project10image14 from './img/Zrealizovane/Project10/image14.jpg';
import Project10image15 from './img/Zrealizovane/Project10/image15.jpg';
import Project10image16 from './img/Zrealizovane/Project10/image16.jpg';



import Project11image1 from './img/Zrealizovane/Project11/image1.jpg';
import Project11image2 from './img/Zrealizovane/Project11/image2.jpg';
import Project11image3 from './img/Zrealizovane/Project11/image3.jpg';

import Project12image1 from './img/Zrealizovane/Project12/image1.jpg';
import Project12image2 from './img/Zrealizovane/Project12/image2.jpg';
import Project12image3 from './img/Zrealizovane/Project12/image3.jpg';
import Project12image4 from './img/Zrealizovane/Project12/image4.jpg';
import Project12image5 from './img/Zrealizovane/Project12/image5.jpg';
import Project12image6 from './img/Zrealizovane/Project12/image6.jpg';
import Project12image7 from './img/Zrealizovane/Project12/image7.jpg';
import Project12image8 from './img/Zrealizovane/Project12/image8.jpg';
import Project12image9 from './img/Zrealizovane/Project12/image9.jpg';

import Project13image1 from './img/Zrealizovane/Project13/image1.jpg';
import Project13image2 from './img/Zrealizovane/Project13/image2.jpg';
import Project13image3 from './img/Zrealizovane/Project13/image3.jpg';
import Project13image4 from './img/Zrealizovane/Project13/image4.jpg';
import Project13image5 from './img/Zrealizovane/Project13/image5.jpg';
import Project13image6 from './img/Zrealizovane/Project13/image6.jpg';

import Project14image1 from './img/Zrealizovane/Project14/image1.jpg';
import Project14image2 from './img/Zrealizovane/Project14/image2.jpg';
import Project14image5 from './img/Zrealizovane/Project14/image5.jpg';
import Project14image6 from './img/Zrealizovane/Project14/image6.jpg';
import Project14image7 from './img/Zrealizovane/Project14/image7.jpg';
import Project14image8 from './img/Zrealizovane/Project14/image8.jpg';
import Project14image9 from './img/Zrealizovane/Project14/image9.jpg';
import Project14image10 from './img/Zrealizovane/Project14/image10.jpg';
import Project14image11 from './img/Zrealizovane/Project14/image11.jpg';
import Project14image12 from './img/Zrealizovane/Project14/image12.jpg';
import Project14image13 from './img/Zrealizovane/Project14/image13.jpg';
import Project14image14 from './img/Zrealizovane/Project14/image14.jpg';
import Project14image15 from './img/Zrealizovane/Project14/image15.jpg';
import Project14image16 from './img/Zrealizovane/Project14/image16.jpg';


// import Project15image1 from './img/Zrealizovane/Project15/Image1.jpg';
// import Project15image2 from './img/Zrealizovane/Project15/Image2.jpg';
// import Project15image3 from './img/Zrealizovane/Project15/Image3.jpg';
// import Project15image4 from './img/Zrealizovane/Project15/Image4.jpg';
// import Project15image5 from './img/Zrealizovane/Project15/Image5.jpg';
// import Project15image6 from './img/Zrealizovane/Project15/Image6.jpg';
// import Project15image7 from './img/Zrealizovane/Project15/Image7.jpg';
// import Project15image8 from './img/Zrealizovane/Project15/Image8.jpg';
// import Project15image9 from './img/Zrealizovane/Project15/Image9.jpg';
// import Project15image10 from './img/Zrealizovane/Project15/Image10.jpg';
// import Project15image11 from './img/Zrealizovane/Project15/Image11.jpg';
// import Project15image12 from './img/Zrealizovane/Project15/Image12.jpg';
// import Project15image13 from './img/Zrealizovane/Project15/Image13.jpg';




const project1 = [
  Project1image1, Project1image2, Project1image3, 
  Project1image4, Project1image5,
  Project1image8,  Project1image9,
  Project1image10, Project1image11
]

const project2 = [
  Project2image1, Project2image2, Project2image3, 
  Project2image4, Project2image5, Project2image6, 
  Project2image7, Project2image8, Project2image9, 
  Project2image10, Project2image11, Project2image12, 
  Project2image13, Project2image14,  
]

const project3 = [
  Project3image1, Project3image2, Project3image3, 
  Project3image4, Project3image5, Project3image6, 
]

const project4 = [
  Project4image8,  Project4image11, Project4image12,
  Project4image13, Project4image14, Project4image15, 
  Project4image16, Project4image19,  Project4image1, 
  Project4image2, Project4image3 
]

const project5 = [
  Project5image1, Project5image2, Project5image3, 
  Project5image4, Project5image5, Project5image6, 
  Project5image8, Project5image9
]

const project6 = [
  Project6image1, Project6image2, Project6image3, 
  Project6image4, Project6image5, Project6image6, 
  Project6image10
]

const project7 = [
  Project7image1, Project7image2,
  Project7image3, Project7image4
]

const project8 = [
  Project8image1, Project8image2, Project8image3
]

const project9 = [
  Project9image1, Project9image2, Project9image3
]

const project10 = [
  Project10image1, Project10image2, Project10image3, 
  Project10image4, Project10image5, Project10image6, 
  Project10image7, Project10image8, Project10image9,
  Project10image10, Project10image11, Project10image12,
  Project10image13, Project10image14, Project10image15,
  Project10image16
]

const project11 = [
  Project11image1, Project11image2, Project11image3
]

const project12 = [
  Project12image1, Project12image2, Project12image3,
  Project12image4, Project12image5, Project12image6,
  Project12image7, Project12image8, Project12image9
]

const project13 = [
  Project13image1, Project13image2, Project13image3,
  Project13image4, Project13image5, Project13image6,
]

const project14 = [
  Project14image1, Project14image2, Project14image5,
  Project14image6, Project14image7, Project14image8, Project14image9,
  Project14image10, Project14image11, Project14image12, Project14image13,
  Project14image14, Project14image15, Project14image16

]

// const project15 = [
//   Project15image1, Project15image2, Project15image3,
//   Project15image4, Project15image5, Project15image6,
//   Project15image7, Project15image8, Project15image9,
//   Project15image10, Project15image11, Project15image12,
//   Project15image13
// ]

const ZrealizovaneData = [
  {
  id:1,
  img: Project11image1,
  images: project11,
  title: 'SENTO BANACHA bud. B1',
  color: 'orange',
  cols: 2,
  hint: 'Status: building',
    investor: 'SENTO',
    localization: '',
    firma: 'Brogan Group Sp. z o.o.',
    notename :[{
      name: '',
      PUM: '7.249 m2',
      PC: '14.043 m2',
  
      }
    ]
  },
  {id:2,
    img: Project7image4,
    images: project7,
    title: 'Osiedle SENSITY, ul. Wielicka Kraków, Bud. B,C,D',
    color: 'orange',
    cols: 2,
    hint: 'Status: building',

    investor: 'SENTO',
    localization: 'Osiedle SENSITY, ul. Wielicka Kraków, Bud. B,C,D',
    firma: 'Brogan Group Sp. z o.o.',   
     
    notename :[{
      name: 'Bud. B ',
      PUM: '3.693 m2',
      PC: '9.803 m2',

    },
    {
      name: 'Bud. C',
      PUM: '2.367 m2',
      PC: '4.238 m2',

    },
    {
      name: 'Bud. D',
      PUM: '2.010 m2',
      PC: '4.295 m2',
 
    },
    ]
},
  {id:3,
  img: Project3image1,
  images: project3,
  title: 'Apartamenty "U Przybyszewskiego"',
  color: 'orange',
  cols: 2,
  hint: 'Status: building',
  
  investor: 'EKOPARK',
  localization: 'Apartamenty " U Przybyszewskiego"',
  firma: 'Brogan Group Sp. z o.o.',   
    
  notename :[{
    name: 'Przybyszewskiego Etap I',
    PUM: '4.440 m2',
    PC: '10.505 m2'
  }
  ]
  },
  {id:4,
    img: Project8image1,
    images: project8,
    title: 'ul. Kątowa 10',
    color: 'orange',
    cols: 2,
    hint: 'Status: building',
  
    investor: 'Apartamenty inwestycyjne IRON STREET',
    localization: 'ul. Kątowa 10',
    firma: 'Brogan Group Sp. z o.o.',   
         
    notename :[{
      name: 'liczba kondygnacji podziemnych: 1',
      PUM: '1.018 m2',
      PC: '2.453 m2'
      },
      {
        name: 'liczba kondygnacji nadziemnych: 5',
        PUM: ''
      }
    ]
  },
  {id:5,
    img: Project2image1,
    images: project2,
    title: 'ul. Fabryczna Osiedle " Art City"  ',
    color: 'orange',
    cols: 2,
    hint: 'Status: building',
  
    investor: 'EKOPARK',
    localization: 'ul. Fabryczna Osiedle " Art City" ',
    firma: 'Brogan Group Sp. z o.o.',   
    notename :[{
      name: 'Fabryczna P1',
      PUM: '4.868 m2 ',
      PC: '10.668 m2'
    },
    {
      name: 'Fabryczna L1 ',
      PUM:'7.534 m2',
      PC: '15.710 m2'
    },
    {
      name: 'Fabryczna P2-P3',
      PUM: '10.387 m2',
      PC: '23.892 m2'
    },
    {
      name: 'Fabryczna P4',
      PUM: '5.161 m2 ',
      PC: '11.369 m2'
    },
    {
      name: 'Sumarycznie całe osiedle: ',
      PUM: '27.950 m2',
      PC: '61.639 m2'
    },
    ]
  },
  {id:6,
    img: Project1image8,
    images: project1,
    title: 'Os. 7- ŻYCZEŃ ul. Sosnowiecka, Kraków',
    color: 'orange',
    cols: 2,
    hint: 'Status: building',
  
    investor: 'EKOPARK',
    localization: 'Os. 7- ŻYCZEŃ ul. Sosnowiecka, Kraków',
    firma: 'Brogan Group Sp. z o.o.',   
      
    notename :[{
      name: 'Sosnowiecka 1-3',
      PUM: '2.825 m2',
      PC: '5.596 m2'
    },
    {
      name: 'Sosnowiecka 4-7',
      PUM:'3.769 m2',
      PC: '7.521 m2'
    },
    {
      name: 'Sosnowiecka 1-4',
      PUM: '1.965 m2',
      PC: '2.942 m2'
    },
    {
      name: 'Sosnowiecka 6-7',
      PUM: '2.101 m2',
      PC: '4.118 m2'
    },
    {
      name: 'Sosnowiecka 18-19',
      PUM: '2.101 m2',
      PC: '4.039 m2'
    },
    {
      name: 'Sosnowiecka 21-23',
      PUM: '3.189 m2',
      PC: '6.214 m2'
    },
    {
      name: 'Razem całe Osiedle 7 Życzeń',
      PUM: '15.950 m2',
      PC: '30.430 m2'
    },
    ]
  },
  {id:7,
    img: Project6image10,
    images: project6,
    title: 'BUNSCHA – budynek biurowy',
    color: 'orange',
    cols: 2,
    hint: 'Status: building',

    investor: 'WAKSMUNDZKI GRUPA INWESTYCYJNA',
    localization: 'ul. Bunscha Kraków ',
    firma: 'Brogan Group Sp. z o.o.',   
     
    notename :[{
      name: 'budynek biurowo- usługowy',
      PUM: '10.565 m2',
      PC: '3.957 m2'
    }]
},
  {id:8,
    img: Project5image1,
    images: project5,
    title: 'LIPSKA – budynek biurowy',
    color: 'orange',
    cols: 2,
    hint: 'Status: building',

    investor: 'BRYKSY',
    localization: 'ul. Lipska',
    firma: 'Brogan Group Sp. z o.o.',   
     
    notename :[{
      name: 'budynek biurowo - usługowy ',
      PUM: '793 m2',
      PC: '2.457 m2'
    }
    ]
},
  {id:9,
  img: Project9image1,
  images: project9,
  title: 'Apartamenty Tetmajera 65B',
  color: 'orange',
  cols: 2,
  hint: 'Status: building',

  investor: 'Apartamenty Tetmajera 65B',
  localization: 'Apartamenty Tetmajera 65B',
  firma: 'Brogan Group Sp. z o.o.',   
    
  notename :[{
    name: 'Tetmajera, budynki I, II, III, IV:',
    PUM: '628 m2',
    PC: '1.080 m2'
    }
  ]
},
  {
  id:10,
  img: Project10image1,
  images: project10,
  title: 'BUDOWA BUDYNKU KOŚCIOŁA PARAFIALNEGO WRAZ Z BUDYNKIEM PLEBANII',
  color: 'orange',
  cols: 2,
  hint: 'Status: building',

  investor: 'TOWARZYSTWO SALEZJAŃSKIE',
  localization: 'ul. Tyniecka, Kraków',
  firma: 'Brogan Group Sp. z o.o.',   
   
  notename :[{
    name: '',
    PUM: '',
    PC: '',
    KUB: ''
    }
  ]
},
  {id:11,
  img: Project4image8,
  images: project4,
  title: 'AVIA Budynek 10',
  color: 'orange',
  cols: 2,
  hint: 'Status: building',
  
  investor: 'BUDIMEX',
  localization: 'AVIA Budynek 10',
  firma: 'Brogan Group Sp. z o.o.',   
    
  notename :[{
    name: 'Ilość mieszkań: 178 na 16 kondygnacjach',
    PUM: '9.068 m2',
    PC: '19.714 m2'
  }
  ]
  },
  {
    id: 12,
    img: Project12image1,
    images: project12,
    title: 'Osiedle SLOW CITY ul. Piaszczysta Kraków',
    color: 'orange',
    cols: 2,
    hint: 'Status: W realizacji',
    investor: 'EKOPARK',
    localization: 'Osiedle SLOW CITY ul. Piaszczysta Kraków',
    firma: 'Brogan Group Sp. z o.o.',
    notename :[
      {
        name: 'liczba kondygnacji podziemnych 1',
        PC: '13.634 m2',
        PUM: '7.434 m2'
      },
      {
        name: 'liczba kondygnacji nadziemnych 4',
        PC: '',
        PUM: ''
      }
    ]
  },
  {
    id: 13,
    img: Project13image2,
    images: project13,
    title: 'Apartamenty u Przybyszewskiego',
    color: 'green',
    cols: 2,
    hint: 'status',

    investor: 'EKOPARK',
    localization: 'Apartamenty u Przybyszewskiego',
    firma: 'Brogan Group Sp. z o.o. ',
    notename :[
      {
        name: 'Przybyszewskiego Etap II',
        PUM: '2.754 m2',
        PC: '6.550 m2'
      }
    ]

  },
  {
    id: 14, //Kod
    img: Project14image7, //Kod Image
    images: project14, //Array image project
    title: 'Osiedle SenTOTU ul. Górka Narodowa Kraków, budynki B1.1, B1.2', //Title
    color: 'green', //Color home
    cols: 2, // counts column in road
    hint: '', //Hint for home
    investor: 'SENTO', //Investor
    localization: 'Osiedle SenTOTU ul. Górka Narodowa Kraków, budynki B1.1, B1.2', //Address
    firma: 'Brogan Group Sp. z o.o.',     //
    notename: [
      {
        name: 'Bud.B2',
        PUM: '5.479 m2',
        PC: '11.161 m2'
      },
      {
        name: 'Bud.B3',
        PUM: '4.704 m2',
        PC: '9.790 m2'
      }

    ]
  },
  // {
  //   id: 15,
  //   img: Project15image1,
  //   images: project15,
  //   title: 'Apartamenty u Przybyszewskiego',
  //   color: 'green',
  //   cols: 2,
  //   hint: 'Status: ',
  //
  //   investor: 'EKOPARK',
  //   localization: 'Apartamenty u Przybyszewskiego',
  //   firma: 'Brogan Group Sp. z o.o. ',
  //   notename: [
  //     {
  //       name: 'Przybyszewskiego etap III',
  //       PUM: '7.888 m2',
  //     }
  //   ]
  //
  // },


]

export default ZrealizovaneData;
